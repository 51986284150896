import { useMediaQuery, useTheme } from '@mui/material';

export const useDevices = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.only('tablet'));
  const isMobile = useMediaQuery(theme.breakpoints.only('mobile'));
  const isDesktopL = useMediaQuery(theme.breakpoints.only('desktopL'));
  const isDesktopXL = useMediaQuery(theme.breakpoints.only('desktopXL'));
  const isDownTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const isDownDesktopL = useMediaQuery(theme.breakpoints.down('desktopL'));

  return {
    isDownDesktopL,
    isTablet,
    isDownTablet,
    isMobile,
    isDesktopL,
    isDesktopXL,
  };
};
