import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { FC, useMemo } from 'react';

import NotFoundWrapper from '@/components/common/404/styles/NotFoundWrapper';
import { useDevices } from '@/utils/hooks/useDevices';
import { routePaths } from '@/utils/routePaths';

interface Props {
  login?: boolean;
  dashboardUrl?: string;
  customer?: boolean
}

const NotFoundPage: FC<Props> = ({ login, dashboardUrl, customer }) => {
  const { push } = useRouter();
  const { isMobile } = useDevices();

  const [width, height] = useMemo(() => {
    if (isMobile) {
      return [240, 110];
    }
    return [392, 180];
  }, [isMobile]);

  return (
    <NotFoundWrapper
      sx={(theme) => ({
        height: dashboardUrl ? '100%' : 'calc(100vh - 64px)',
        borderRadius: login ? 0 :'20px',
        [theme.breakpoints.up('tablet')]: {
          width: ((dashboardUrl || login) && !customer) ? '100%' : 'calc(100% - 64px)',
          mx: ((dashboardUrl || login) && !customer) ? 0 : 4,
          borderRadius: dashboardUrl ? 2.5 : 0,
        },
        [theme.breakpoints.up('desktopXL')]: {
          minHeight: dashboardUrl ? 'calc(100vh - 200px)' : '100vh',
        },
      })}
    >
      <Grid display="flex" flexDirection="column" alignItems="center">
        <Image src="/images/404.png" alt="404" width={width} height={height} />
        <Typography
          variant="h1"
          sx={(theme) => ({
            mt: 5,
            mb: 2.5,
            [theme.breakpoints.up('tablet')]: { mt: 11.5 },
          })}
        >
          Oops!
        </Typography>
        <Typography variant="h4" sx={{ mb: 5, px: 2.5, textAlign: 'center' }}>
          the page you&apos;re looking for cannot be found
        </Typography>
        {login && (
          <Button onClick={() => push(routePaths.commonLogin)}>
            Go to Login
          </Button>
        )}
        {dashboardUrl && (
          <Button onClick={() => push(dashboardUrl)}>Go to Dashboard</Button>
        )}
      </Grid>
    </NotFoundWrapper>
  );
};

export default NotFoundPage;
